import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "../css/Test.css"
// import "./customCarousel.css"; 
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

export const Test = () => {

    const test_datas=[
        {
            img:"./assets/user1.png",
            desc:"Desc1 Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus perferendis, animi ipsam adipisci earum sapiente ducimus pariatur pr",
            name:"Yashvanthi",
            role:"JS developer"
        },
        {
            img:"./assets/user2.jpg",
            desc:"Desc2 Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus perferendis, animi ipsam adipisci earum sapiente ducimus pariatur pr",
            name:"Keerthana",
            role:"JS developer"
        },
        {
            img:"./assets/user3.jpg",
            desc:"Desc3 Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus perferendis, animi ipsam adipisci earum sapiente ducimus pariatur pr",
            name:"Nithish",
            role:"JAVA developer"
        },
      
    ]

  return (
    <div className='test_container'>
        <div className='test_heading'>
            <p data-aos="fade-up" data-aos-duration="1000">See What People are Saying</p>
        </div>
            <TestCard test_datas={test_datas}/>
    </div>
  )
}



const TestCard = ({test_datas}) => {
  return (
    <div className='test_cards' data-aos="fade-up" data-aos-duration="1000">

<Carousel 
 infiniteLoop={true}
 autoPlay={true}
 interval={2000}
 transitionTime={700}
 > 

    {test_datas.map((data)=>(
                <div className='test_card'>
                        <div className='img_outer'>
                            <div className='test_img'>
                                    <img src={data.img} alt="" />
                            </div>
                        </div>
            
                        <div className='test_card-right'>
                                <div className='test_desc_box'>
                                        <div className='comma_box_left '><i class="ri-double-quotes-l comma"></i></div>
                                        <p className='test_desc'>{data.desc}</p>
                                        <div className='comma_box_right'><i class="ri-double-quotes-r comma"></i></div>
                                </div>
                                <p className='test_name'>{data.name}</p>
                                <p className='test_role'>{data.role}</p>
                        </div>
                 </div>
    ))}  

</Carousel>   
    </div>
  )
}
