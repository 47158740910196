import React, { useState } from 'react';
import "../css/Location.css";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

const locations = [
  { city: "USA", address: "2880 Zanker Road,Suite 203, San Jose, CA 95134", imgSrc: "/assets/usa1.jpg", position: { lat: 37.3959384, lng: -121.9293042 } },
  { city: "Bangalore", address: "#104, T1, Third Floor, 13th Main Road, Jakkasandra, Sector 7, HSR Layout 5th Sector, Bengaluru – 560034", imgSrc: "/assets/bangalore.jpg", position: { lat: 12.9186465, lng: 77.6377147 } },
  { city: "Vellore", address: "S K Plaza, 2nd Floor, No 5, Old Bye Pass Road, Opp Hotel Benzz Park, Vellore – 632004", imgSrc: "/assets/vellore1.jpg", position: { lat: 12.929529, lng: 79.1307794 } },
];

export const Location = () => {
  const [mapCenter, setMapCenter] = useState({ lat: 12.9716, lng: 77.5946 });
  const [zoomLevel, setZoomLevel] = useState(2);

  const handleLocationChange = (position) => {
    setMapCenter(position);
    setZoomLevel(12);
  };

  return (
    <div className='location-container' id='xp_location'>
      <div className='location-box'>
        <div className='location-title' data-aos="fade-up" data-aos-duration="1000">Locations</div>
        <div className='loc-cards' data-aos="fade-up" data-aos-duration="1000">
          <Card datas={locations} onLocationChange={handleLocationChange} />
        </div>
      </div>
      <MapBox center={mapCenter} zoom={zoomLevel} />
    </div>
  );
};

const Card = ({ datas, onLocationChange }) => {
  return (
    datas.map((data, index) => (
      <div className="loc-card" key={index}>
        <div className='imgBx'>
          <img src={data.imgSrc} alt={data.city} />
        </div>
        <p>{data.address}</p>
          <a href="#xp_map"><button className='loc_btn' onClick={() => onLocationChange(data.position)}>{data.city}</button></a> 
      </div>
    ))
  );
};

const MapBox = ({ center, zoom }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_MAP_GOOGLE_MAPS_API_KEY,
  });

  if (!isLoaded) return <div>Loading...</div>;

  const markers = [
    { position: { lat: 37.3959384, lng: -121.9293042 }, label: "Xp", title: "Xploria USA" },
    { position: { lat: 12.9186465, lng: 77.6377147 }, label: "Xp", title: "Xploria Bangalore" },
    { position: { lat: 12.929529, lng: 79.1307794 }, label: "Xp", title: "Xploria Vellore" },
  ];

  return (
    <div className='gmap' id='xp_map'>
      <GoogleMap
        center={center}
        zoom={zoom}
        mapContainerStyle={{ width: '100%', height: '350px' }}
        options={{
          zoomControl: true,
          streetViewControl: true,
          mapTypeControl: true,
          fullscreenControl: true
        }}
      >
        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={marker.position}
            label={marker.label}
            title={marker.title}
          />
        ))}
      </GoogleMap>
    </div>
  );
};
