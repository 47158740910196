import { useState,useEffect } from "react";
import "../css/Navbar.css";

export const Navbar = ({setShowContact}) => {
  const [menu, showMenu] = useState(false);
  const [arrowDrop,showArrowDrop] =useState(true)
  const [headerColor, setHeaderColor] = useState(false);

  const toggleMenu = () => {
    showMenu((prev) => !prev);
    setHeaderColor((prev) => !prev);
  };

  const arrowDropup=()=>{
        showArrowDrop((pre)=>!pre)
  }
  // const arrowDropdown=()=>{
  //   showArrowDrop(false)
  // }


  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector(".header");
      if (window.scrollY > 50) {
        navbar.classList.add("scrolled");
      } else {
        navbar.classList.remove("scrolled");
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleContactClick = (e) => {
    e.preventDefault(); 
    setShowContact(true);
  };
  const returnHome =()=>{
    setShowContact(false)
  }

  return (
    <header className={`header ${headerColor ? "header--black" : ""}`}> 
      <nav className="nav container">

        <div className="nav__data">
          
              <div className="logo">
                   <img src="/assets/logo.png" alt="Logo" onClick={returnHome}/>
                    <span className="logo-text">Xploria</span>
              </div>

          <div className={`nav__toggle ${menu ? 'show-icon' : ''}`} id="nav__toggle" onClick={toggleMenu}>
              <i className="ri-menu-2-line nav__toggle-menu"></i>
              <i className="ri-close-line nav__toggle-close"></i> 
          </div>
        </div>

        <div className={`nav__menu ${menu ? 'show-menu' : ''}`} id="nav-menu">
          <ul className="nav__list">
            <li className="dropdown__item">
              {/* <a href="#" className="nav__link">Home</a> */}
            </li>

            <li className="dropdown__item">
              <div className="nav__link dropdown__button" onClick={arrowDropup} >
                Our Expertise<i className={`ri-arrow-down-s-line dropdown__arrow ${arrowDrop ? 'dropdown__arrow-rotate':''}`} ></i>
              </div>
              {/* drop down */}
              <div className={`dropdown__container ${arrowDrop ?'show-dropdown':''}`}>
                   
                        <div className="dropdown__group">
                                <ul className="dropdown__list">
                                    <li>
                                        <i class="ri-settings-4-line dropdown__icons"></i>
                                        <a href="#" className="dropdown__link">PLM & Engineering Services</a>
                                    </li>

                                    <li>
                                        <i class="ri-lightbulb-flash-line dropdown__icons"></i>
                                        <a href="#" className="dropdown__link">Business Applications</a>
                                    </li>

                                    <li>
                                        <i class="ri-customer-service-2-line dropdown__icons"></i>
                                        <a href="#" className="dropdown__link">Technology Consulting</a>
                                    </li>

                                    <li>
                                        <i class="ri-line-chart-line dropdown__icons"></i>
                                        <a href="#" className="dropdown__link">AI/ML & Data Analytics</a>
                                    </li>

                                    <li>
                                        <i class="ri-base-station-line dropdown__icons"></i>
                                        <a href="#" className="dropdown__link">IT Operational Excellence</a>
                                    </li>

                                    <li>
                                         <i class="ri-shield-star-line dropdown__icons"></i>
                                        <a href="#" className="dropdown__link">Quality Engineering</a>
                                    </li>
                                </ul>
                        </div>
                   
              </div>

            </li>

            <li className="dropdown__item">
              <a href="" className="nav__link" onClick={returnHome}>Careers</a>
            </li>
            <li className="dropdown__item">
              <a href="#xp_location" className="nav__link" onClick={returnHome}>Locations</a>
            </li>
            <li className="dropdown__item">
              <a href="#" className="nav__link" onClick={handleContactClick}>Contact Us</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};
