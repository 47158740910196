import "../css/Footer.css"
import { Brand } from "./HomePage"


export const Footer = () => {
  return (
   
    <footer>
        <div class="footer-container">
            <div class="footer-section">
                    <div className='footer-brand'>
                            <a href=""><img src="/assets/xp_logo.png" alt="" /></a>
                            <div className="footer-brandname">XPLORIA</div>
                    </div> 
            </div>
            <div class="footer-section">
                <h4>What We Do ?</h4>
                <div className="box">
                    <a href="#">PLM & Engineering Services</a>
                    <a href="#">Business Applications</a>
                    <a href="#">Technology Consulting</a>
                    <a href="#">AI/ML & Data Analytics</a>
                    <a href="#">IT Operational Excellence</a>
                    <a href="#">Quality Engineering</a>
                </div>
            </div>
            <div class="footer-section">
                <h4>Quick Links</h4>
                <div className="box">
                    <a href="#">Home</a>
                    <a href="#">Our Expertise</a>
                    <a href="#">Careers</a>
                    <a href="#">Locations</a>
                    <a href="#">Contact Us</a>
                </div>
            </div>
            <div class="footer-section">
                <h4>Follow Us</h4>
                <div class="social-icons">
                    <a href="#"><img src="assets/facebook.png" alt="Facebook"/></a>
                    <a href="#"><img src="assets/linkedin.png" alt="Twitter"/></a>
                    <a href="#"><img src="assets/twitter.png" alt="LinkedIn"/></a>
                </div>
            </div>
        </div>

        <div className="footer-bottom-container">
            <p>Xploria © Copyright 2022 | All rights reserved.</p>
            <a href="">Privacy Policy</a>
        </div>
    </footer>
   
  )
}
