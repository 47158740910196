import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { HomePage, IconsSlide, LandingPage } from "./components/HomePage";
import { Location } from "./components/Location";
import { Footer } from "./components/Footer";
import { Loading } from "./components/Loading";
import { Counter } from "./components/Counter";
import { Test } from "./components/Test";
import { EmpEng } from "./components/EmpEng";
import { ContactPage } from "./components/ContactPage";
import { Navbar } from "./components/Navbar";
import OurExpertise from "./components/OurExpertise";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [showContact, setShowContact] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);

  return (
    <React.StrictMode>
      {loading ? (
        <Loading />
      ) : (
        <>
          {showContact ? (
            <>
              <Navbar setShowContact={setShowContact} />
              <ContactPage />
              <Footer />
            </>
          ) : (
            <>
              <Navbar setShowContact={setShowContact} />
              <LandingPage />
              <OurExpertise/>
              <Counter />
              <IconsSlide />
              <Test />
              <EmpEng/>
              <Location />
              <Footer />
            </>
          )}
        </>
      )}
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
