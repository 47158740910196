
import "../css/ContactPage.css"

export const ContactPage = () => {
  return (
    <div className="contact">
            <div className="contact_head">
                            <p className="contact_heading">Let’s Keep In Touch</p>
                            <p className="contact_para">For general queries, sales and partnerships please drop a email anytime and we will reach you</p>
                        </div>
        <div className="contact_container">
            <div className="contact_left">
                            <div className="contact_us">
                                <i class="fa-solid fa-circle right_icons"></i><h2 className="contact_us_tittle">Contact Us</h2>
                            </div>
                   <div className="input_fields">
                            <div className="name_input">
                                <div className="first_name_input_box">
                                    <input type="text"  placeholder="First Name"/>
                                    <i class="fa-solid fa-user name_icons"></i>  
                                </div>
                                <div className="last_name_input_box">
                                    <input type="text"  placeholder="Last Name"/>
                                    <i class="fa-solid fa-user name_icons"></i>
                                </div>
                            </div>
                            <div className="gmail_input">
                                    <input type="text" placeholder="Gmail" />
                                    <i class="ri-mail-fill input_icons"></i>
                            </div>
                            <div className="phone_input">
                                    <input type="text" placeholder="Phone" />
                                    <i class="ri-cellphone-fill input_icons"></i>
                            </div>
                            <div className="subject_input">
                                    <input type="text" placeholder="Subject" />
                                    <i class="ri-file-text-fill input_icons"></i>
                            </div>
                            <div className="subject_input">
                                    <textarea  cols="30" rows="5" placeholder="Your Message"></textarea>
                            </div>
                   </div>
                   <div className="btn_section">
                        <button className="attachment_btn"><i class="ri-link attachment_icon"></i>Add Attachment</button>
                        <button className="send_btn">Send message</button>
                   </div>
            </div>

            <div className="contact_right">
                        <div className="reach_us">
                            <i class="fa-solid fa-circle right_icons"></i><h2 className="reach_us_tittle"> Reach Us</h2>
                        </div>
                        <div className="location_box">
                            <i class="ri-map-pin-line right_icons"></i>
                            <p>#104, T1, Third Floor,
                                13th Main Road, Jakkasandra,
                                Sector 7, HSR Layout 5th Sector,
                                Bengaluru – 560034</p>
                        </div>
                        <div className="phone_box">
                             <i class="ri-phone-line right_icons"></i>
                             <p>+91990903-3806</p>
                        </div>
                        <div className="email_box">
                            <i class="ri-mail-send-line right_icons"></i>
                            <p>adminxploria@gmail.com</p>
                        </div> 
                        <div className="icons_box">
                            <i class="ri-facebook-fill social_icons"></i>
                            <i class="ri-twitter-fill social_icons"></i>
                            <i class="ri-google-fill social_icons"></i>
                        </div>    
            </div>
        </div>
    </div>
  )
}

