import React, { useEffect, useRef, useState } from "react";
import "../css/OurExpertise.css";
import expertiseImages from "../components/OurExpImg.jsx"


 export const OurExpertise = () => {
  const sliderRef = useRef(null);
  const thumbnailRef = useRef(null);
  const [items, setItems] = useState(expertiseImages);
  const [thumbnailItems, setThumbnailItems] = useState(
    expertiseImages.slice(1).concat(expertiseImages[0])
  );
  const [carouselClass, setCarouselClass] = useState("");
  const nextTimeRunning = 1000;
  const prevTimeRunning = 3000;
  const timeAutoNext = 7000;

  useEffect(() => {
    const runNextAuto = setInterval(() => showSlider("next"), timeAutoNext);
    return () => clearInterval(runNextAuto);
  }, [items]);

  const showSlider = (type, index = null) => {
    let newItems, newThumbnailItems;
    if (type === "next" || type === "prev") {
      if (type === "next") {
        newItems = [...items.slice(1), items[0]];
        newThumbnailItems = [...thumbnailItems.slice(1), thumbnailItems[0]];
        setCarouselClass("next");
      } else {
        newItems = [
          items[items.length - 1],
          ...items.slice(0, items.length - 1),
        ];
        newThumbnailItems = [
          thumbnailItems[thumbnailItems.length - 1],
          ...thumbnailItems.slice(0, thumbnailItems.length - 1),
        ];
        setCarouselClass("prev");
      }
    } else if (index !== null) {
      newItems = [...items.slice(index), ...items.slice(0, index)];
      newThumbnailItems = [
        ...thumbnailItems.slice(index),
        ...thumbnailItems.slice(0, index),
      ];
      setCarouselClass("next");
    }

    setItems(newItems);
    setThumbnailItems(newThumbnailItems);
    setTimeout(() => setCarouselClass(""), nextTimeRunning);
  };

  return (
    <div className={`carousel ${carouselClass}`}>
      <div className="list" ref={sliderRef}>
        {items.map((item, index) => (
          <div className="item" key={index}>
            <div className="gradient"></div>
            <img src={item.image} alt={`Slide ${index + 1}`} />
            <div className="contentexp">
              <div className="exp_author">{item.heading}</div>
              <div className="title">{item.title}</div>
              <div className="des">{item.description}</div>
              <div className="buttons">
                <button>SEE MORE</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="thumbnail" ref={thumbnailRef}>
        {thumbnailItems.map((item, index) => (
          <div
            className="item clickable"
            key={index}
            onClick={() => showSlider("thumbnail", index + 1)}
          >
            <img
              src={item.image}
              alt={`Thumbnail ${index + 1}`}
              style={{ cursor: "pointer" }}
            />
            <div className="title">{item.heading}</div>
            <div className="content"></div>
          </div>
        ))}
      </div>
      <div className="arrows">
        <button id="prev" onClick={() => showSlider("prev")}>
          {"<"}
        </button>
        <button id="next" onClick={() => showSlider("next")}>
          {">"}
        </button>
      </div>
    </div>
  );
};

export default OurExpertise;
