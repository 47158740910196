import React, { useState } from "react";
import "../css/EmpEng.css"; // Import your CSS file


export const EmpEng = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const images = [
    {
      img:"/assets/image1.jpg",
      title: "Exploring New Horizons",
      description:
        "Exploring new places and creating lasting memories - our team’s trip to Gokarna was filled with adventure and joy.",
    },
    {
      img: "/assets/image2.jpg",
      name: "Samite",
      role: "WordPress Developer",
      description:
        "Samite specializes in creating dynamic and user-friendly WordPress sites.",
    },
    {
      img:"/assets/image3.jpg",
      name: "Hashi",
      role: "Java Developer",
      description:
        "Hashi is proficient in Java and has experience in building scalable applications.",
    },
    {
      img: "/assets/image4.jpg",
      name: "Kaity",
      role: "Web Developer",
      description:
        "Kaity excels in front-end development and creating responsive web designs.",
    },
    {
      img: "/assets/image5.jpg",
      name: "Lauren",
      role: "PHP Developer",
      description:
        "Lauren is a PHP developer with a knack for building robust web applications.",
    },
  ];

  const handleImageClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <section className="slider-container">
      <div className="slider-images">
        {images.map((image, index) => (
          <div
            key={index}
            className={`slider-img ${index === activeIndex ? "active" : ""}`}
            onClick={() => handleImageClick(index)}
          >
            <img
              src={image.img}
              alt={image.title || `${image.name} - ${image.role}`}
            />
            <div className="details">
              {index === 0 ? (
                <>
                  <h2 className="emp_img_title">{image.title}</h2>
                  <p className="emp_img_desc">{image.description}</p>
                </>
              ) : (
                <>
                  <h2>{image.name}</h2>
                  <p className="emp_img_role">{image.role}</p>
                  {index === activeIndex && (
                    <div className="image-description">
                      <p>{image.description}</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

