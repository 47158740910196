

export const expertiseImages = [
  {
    id: 1,
    image:"/assets/AI.jpg",
    heading: "AI/ML & Data Analytics",
    description:
      "Build a scalable GPU powered AI/ML platform to provide your business insights",
    title: "AI/ML & Data Analytics",
  },
  {
    id: 2,
    image:"/assets/Business.jpg",
    heading: "Business Applications",
    description:
      "Build a scalable, secure, robust platform by leveraging the cutting edge technologies and enhanced UX.",
    title: "Business Applications",
  },
  {
    id: 3,
    image:"/assets/Technology.jpg",
    heading: "Technology Consulting",
    description:
      "Design, harmonize and automate business processes with improved flow and velocity across the value stream.",
    title: "Technology Consulting",
  },
  {
    id: 4,
    image:"/assets/Quality.jpg",
    heading: "Quality Engineering",
    description:
      "Helps the organization with comprehensive QA Services & Analysis",
    title: "Quality Engineering",
  },
  {
    id: 5,
    image:"/assets/PLM.jpg",
    heading: "PLM & Engineering Services",
    description:
      "Enable the digital thread through PLM implementations, integrations, and application ecosystem optimization",
    title: "PLM & Engineering Services",
  },
  {
    id: 6,
    image:"/assets/IT_Operation.jpg",
    heading: "IT Operational Excellence",
    description: "Optimize, improve and manage the Business applications 24×7",
    title: "IT Operational Excellence",
  },
];

export default expertiseImages;
